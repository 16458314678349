"use client"

import { useEffect } from "react"
import { redirect, usePathname } from "next/navigation"

import { pagesPath } from "@/config/$path"
import { useCurrentUser } from "@/app/_hooks/useCurrentUser"
import { useCurrentWorkingCompany } from "@/app/_hooks/useCurrentWorkingCompany"

export default function RedirectProvider() {
  const { userId, email, stateReady: authStateReady } = useCurrentUser() ?? {}
  const { company, stateReady: companyStateReady } = useCurrentWorkingCompany()

  const { companyId } = company ?? {}
  const expiredAtHash = company?.expiredAt?.toDateString()

  const pathname = usePathname()

  const stateReady = authStateReady && companyStateReady

  // !WARNING: 一歩間違えると、無限ループのリスクがある
  useEffect(() => {
    if (!stateReady) {
      return
    }

    const authPath = pagesPath.auth.$url().path
    // 未認証ユーザーを /auth へリダイレクト
    if (userId == null || email == null) {
      if (!pathname.startsWith(authPath)) {
        redirect(authPath)
      } else {
        return
      }
    }

    if (companyId == null) {
      const joinPath = pagesPath.join.$url().path
      if (pathname.startsWith("/K-")) {
        redirect(joinPath)
      }

      if (!pathname.startsWith(joinPath)) {
        redirect(joinPath)
      }

      return
    }

    if (expiredAtHash && !(pathname === "/suspended")) {
      redirect(pagesPath.suspended.$url().path)
    }

    if (!pathname.includes(companyId) && !(pathname === "/suspended")) {
      redirect(pagesPath._companyId(companyId).$url().path)
    }
  }, [userId, email, stateReady, companyId, expiredAtHash, pathname])

  return <div></div>
}
